import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Icon } from '@iconify/react';
import styles from './styles/header.module.scss';
import logoSrc from '../../assets/img/logo_fs_sinfondo_blanco.png';
import calendarIcon from '../../assets/img/calendarIcon.svg';
import Notification from '../notification/notification.component';
import { logout } from '../../services/auth/AuthService';
import { getImage } from '../../services/BlobService';
import { State } from '../../utils/StateEnum';
import { busyUser, connectUser, disconnectUser } from '../../services/UserStateService';
import { connect } from 'react-redux';
import { setUserStatus } from '../../redux/slices/userSlice';
import { clearTenant } from '../../redux/slices/tenantSlice';
import Avatar from '../avatar/avatar.component';
import { hasFamiliarRole } from "../../utils/credentials.util";

const mapStateToProps = (state) => ({
  userId: state.user.id,
  userStatus: state.user.userStatus,
  userAvatar: state.user.avatar,
  userName: state.user.name ? state.user.name : state.user.email,
});

const mapDispatchToProps = {
  setUserStatus,
  clearTenant,
};

const menu = [
  {
    key: 'profile',
    icon: 'mage:user',
    label: 'MENU.LABEL.Profile',
    action: 'profile',
  },
  {
    key: 'config',
    icon: 'vaadin:cog-o',
    label: 'MENU.LABEL.Config',
    action: 'config',
  },
  {
    key: 'notifications',
    icon: 'fluent:alert-24-regular',
    label: 'MENU.LABEL.Notifications',
    action: 'notifications',
  },
  {
    key: 'help',
    icon: 'formkit:help',
    label: 'MENU.LABEL.Help',
    action: 'help',
  },
];

const statusOptions = [
  {
    key: State.ONLINE,
    label: 'STATE.Online',
    icon: 'mdi:tick-circle',
    iconClass: 'online',
  },
  {
    key: State.BUSY,
    label: 'STATE.Busy',
    icon: 'tdesign:minus-circle-filled',
    iconClass: 'busy',
  },
  {
    key: State.OFFLINE,
    label: 'STATE.Offline',
    icon: 'mdi:close-circle',
    iconClass: 'offline',
  },
];

const IconWithLabel = ({ icon, label, onClick }) => {
  return (
    <div className={styles.iconWithLabel} onClick={onClick}>
      <Icon icon={icon} className={styles.menuIcon} />
      <div className={styles.label}>{label}</div>
    </div>
  );
};

const Logo = () => (
  <img loading="lazy" src={logoSrc} className={styles.logo} alt="Company logo" />
);

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropdown: false,
      showStatusDropdown: false,
      currentStatus: statusOptions.find(status => status.key === props.userStatus) || statusOptions[0],
      imageSrc: '',
      isLoading: false
    };
    this.dropdownRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.userAvatar) {
      this.fetchImage(this.props.userAvatar);
    }
    document.addEventListener("mousedown", this.handleClickOutside);
    this.props.setUserStatus({currentStatus: this.state.userStatus??State.ONLINE});
  }

  componentDidUpdate(prevProps) {
    if (prevProps.userAvatar !== this.props.userAvatar && this.props.userAvatar) {
      this.fetchImage(this.props.userAvatar);
    }
    if (prevProps.userStatus !== this.props.userStatus) {
      const newStatus = statusOptions.find(status => status.key === this.props.userStatus) || statusOptions[0];
      this.setState({ currentStatus: newStatus });
    }
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  fetchImage = async (imageId) => {
    if (imageId) {
      this.setState({ isLoading: true });
      try {
        const url = await getImage(imageId);
        this.setState({ imageSrc: url, isLoading: false });
      } catch (error) {
        console.error('Error fetching image:', error);
        this.setState({ isLoading: false });
      }
    } else {
      this.setState({ isLoading: false });
    }
  };

  handleClickOutside = (event) => {
    if (this.dropdownRef.current && !this.dropdownRef.current.contains(event.target)) {
      this.setState({ showDropdown: false, showStatusDropdown: false });
    }
  };

  handleCalendarClick = () => {
    this.props.onIconClick('calendar');
  };

  handleProfileClick = () => {
    this.setState((prevState) => ({
      showDropdown: !prevState.showDropdown
    }));
  };

  handleDropdownClick = async (option, view) => {
    if (option === 'logout') {
      await logout();
      this.setState({ showDropdown: false });
      window.location.replace('/login');
      return;
    }

    if (option === 'changeProfile') {
      this.setState({ showDropdown: false });
      this.props.clearTenant();
      window.location.replace('/tenants');
      return;
    }

    if (option === 'profile') {
      if (!hasFamiliarRole()) {
        this.props.onIconClick('contact');
      }
      setTimeout(() => {
        this.props.onIconClick('profile', view);
        if (hasFamiliarRole()) {
          setTimeout(() => {
            this.props.onIconClick('profile', view);
          }, 0);
        }
      }, 0);

    } else {
      this.props.onIconClick(option, view);
    }

    this.setState({ showDropdown: false });
  };

  handleStatusClick = () => {
    this.setState((prevState) => ({
      showStatusDropdown: !prevState.showStatusDropdown,
    }));
  };

  handleStatusSelect = async (status) => {
    const { userId, setUserStatus } = this.props;
    const selectedStatus = statusOptions.find(opt => opt.key === status);
    this.setState({ currentStatus: selectedStatus, showStatusDropdown: false });
    // no deberia estar aqui porque se esta seteando a false, pero no se esta actualizando correctamente.
    this.handleStatusClick();

    setUserStatus({ status });

    if (status === State.ONLINE) {
      await connectUser(userId);
    } else if (status === State.BUSY) {
      await busyUser(userId);
    } else if (status === State.OFFLINE) {
      await disconnectUser(userId);
    }
    this.setState({ showDropdown: false });
  };

  render() {
    const { t, type, userName } = this.props;
    const { showDropdown, showStatusDropdown, currentStatus, imageSrc } = this.state;
    const hasFamilyRole = hasFamiliarRole();
    return (
      <header className={styles.header} style={{ padding: type === 'simple' ? '26px 50px' : '5px 20px 0px' }}>
        <Logo />
        {type !== 'simple' && (
          <nav className={styles.nav}>
            {!hasFamilyRole && (
              <>
                <Notification />
                <img
                  loading="lazy"
                  src={calendarIcon}
                  className={styles.calendarIcon}
                  alt="Calendar icon"
                  onClick={this.handleCalendarClick}
                />
              </>

            )}

            <div className={styles.navLinks} ref={this.dropdownRef}>
              <div className={styles.navLink} onClick={this.handleProfileClick}>
                <Avatar
                  image={imageSrc}
                  name={userName || "User"}
                  size="avatar-lite"
                  status={currentStatus.key}
                  backgroundColor="#123456"
                />
              </div>

              {showDropdown && (
                <div className={styles.dropdownMenu}>
                  <div className={styles.statusRow} onClick={this.handleStatusClick}>
                    <div className={styles[currentStatus.iconClass]}>
                      <Icon icon={currentStatus.icon} />
                      <span>{t(currentStatus.label)}</span>
                    </div>
                    <Icon icon="mingcute:right-line" className={styles.arrowIcon} />
                    {showStatusDropdown && (
                      <div className={styles.statusDropdown}>
                        {statusOptions.map((status) => (
                          <div
                            key={status.key}
                            className={styles.dropdownButton}
                            onClick={() => this.handleStatusSelect(status.key)}
                          >
                            <Icon icon={status.icon} className={styles[status.iconClass]} />
                            <span className={styles[status.iconClass]}> {t(status.label)}</span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className={styles.section1}>
                    {menu.map((option) => (
                      <button
                        className={styles.dropdownButton}
                        key={option.key}
                        onClick={() => {
                          this.handleDropdownClick('profile', option.action);
                        }}
                      >
                        <IconWithLabel
                          icon={option.icon}
                          label={t(option.label)}
                          menuItemKey={option.key}
                        />
                      </button>
                    ))}
                  </div>
                  <button
                    className={styles.dropdownButton}
                    key='changeProfile'
                    onClick={() => {
                      this.handleDropdownClick('changeProfile');
                    }}
                  >
                    <IconWithLabel
                      icon='mage:user'
                      label={t('MENU.LABEL.ChangeProfile')}
                      menuItemKey='changeProfile'
                    />
                  </button>
                  <button
                    className={styles.dropdownButton}
                    key='logout'
                    onClick={() => {
                      this.handleDropdownClick('logout');
                    }}
                  >
                    <IconWithLabel
                      icon='ic:outline-logout'
                      label={t('MENU.LABEL.Logout')}
                      menuItemKey='logout'
                    />
                  </button>
                </div>
              )}
            </div>
          </nav>
        )}
      </header>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Header));
