import React, { useState, useRef, useEffect } from "react";
import styles from "./styles/homesTable.module.scss";
import { withTranslation } from "react-i18next";
import { Icon } from '@iconify/react';

const HomeRow = ({ item, onStatusChange, onEdit, onDelete, hasManagementPermissions, t }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [coords, setCoords] = useState({ top: 0, left: 0 });
  const dropdownRef = useRef(null);
  const triggerRef = useRef(null);

  const handleDropdownToggle = () => {
    if (!showDropdown && triggerRef.current) {
      const rect = triggerRef.current.getBoundingClientRect();
      const dropdownHeight = 90;
      const dropdownWidth = 130;
      const availableBottom = window.innerHeight - rect.bottom;
      let top, left;
      if (availableBottom < dropdownHeight) {
        top = rect.top - dropdownHeight;
      } else {
        top = rect.bottom;
      }
      left = rect.right - dropdownWidth;
      setCoords({ top, left });
    }
    setShowDropdown(!showDropdown);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        triggerRef.current &&
        !triggerRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    if (showDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDropdown]);

  return (
    <tr>
      <td>
        <div className={styles.homeInfo}>
          <div className={styles.homeTitle}>{item.name}</div>
          <div className={styles.homeId}>{item.niu}</div>
        </div>
      </td>
      <td>{item.address}</td>
      <td>{item.locality}</td>
      <td>{item.province}</td>
      <td>{item.postalCode}</td>
      <td className={styles.status}>
        <div className={styles.statusGroup}>
          <div className={styles.statusText}>
            {item.active ? t("HOMES.LABEL.Activated") : t("HOMES.LABEL.Deactivated")}
          </div>
          {hasManagementPermissions && (
            <label className={styles.switch}>
              <input
                type="checkbox"
                checked={item.active}
                onChange={() => onStatusChange(item.id, !item.active)}
              />
              <span className={`${styles.slider} ${styles.round}`}></span>
            </label>
          )}
        </div>
      </td>
      {hasManagementPermissions && (
        <td>
          <div className={styles.dropdownContainer} ref={dropdownRef}>
            <Icon
              icon="solar:menu-dots-bold"
              className={styles.dropIcon}
              width="20"
              height="20"
              onClick={handleDropdownToggle}
              ref={triggerRef}
            />
            {showDropdown && (
              <div
                className={styles.dropdownMenu}
                style={{ position: 'fixed', top: coords.top, left: coords.left }}
              >
                <button onClick={() => { onEdit(item); setShowDropdown(false); }}>
                  <Icon icon="akar-icons:edit" className={styles.iconButton} width="20" height="20" />
                  {t("HOMES.LABEL.Edit")}
                </button>
                <button onClick={() => { onDelete(item.id); setShowDropdown(false); }}>
                  <Icon icon="gravity-ui:trash-bin" className={styles.iconButton} width="20" height="20" />
                  {t("HOMES.LABEL.Delete")}
                </button>
              </div>
            )}
          </div>
        </td>
      )}
    </tr>
  );
};

export default withTranslation()(HomeRow);
