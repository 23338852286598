import React, { useState, useRef, useEffect } from "react";
import styles from "./styles/usersTable.module.scss";
import { withTranslation } from "react-i18next";
import { Icon } from "@iconify/react";
import TagTooltip from "../../tags/tag-tooltip/tagTooltip.component";

const UserRow = ({ item, onStatusChange, onEdit, onDelete, onViewAll, t, hasManagementPermissions }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [coords, setCoords] = useState({ top: 0, left: 0 });
  const dropdownRef = useRef(null);
  const triggerRef = useRef(null);

  const handleDropdownToggle = () => {
    if (!showDropdown && triggerRef.current) {
      const rect = triggerRef.current.getBoundingClientRect();
      const dropdownHeight = 90;
      const dropdownWidth = 130;
      const spaceBelow = window.innerHeight - rect.bottom;
      let top, left;
      if (spaceBelow < dropdownHeight) {
        top = rect.top - dropdownHeight;
      } else {
        top = rect.bottom;
      }
      left = rect.right - dropdownWidth;
      setCoords({ top, left });
    }
    setShowDropdown(!showDropdown);
  };

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      triggerRef.current &&
      !triggerRef.current.contains(event.target)
    ) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    if (showDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDropdown]);

  return (
    <tr>
      <td>
        <div className={styles.usersInfo}>
          <div>
            <div className={styles.usersTitle}>
              {item.homes.length === 1 && item.homes[0] && item.homes[0].name}
              {item.homes.length > 1 && (
                <>
                  {t("USERS.LABEL.Multiple")}
                  <button onClick={() => onViewAll(item.homes)} className={styles.viewAllButton}>
                    {t("USERS.LABEL.ViewAll")}
                  </button>
                </>
              )}
            </div>
            <div className={styles.usersDescription}>
              {item.homes[0] ? item.homes[0].niu : t("HOMES.LABEL.NoHome")}
            </div>
          </div>
        </div>
      </td>
      <td>
        <div className={styles.usersInfo}>
          <div>{item.name + " " + item.surname}</div>
        </div>
      </td>
      <td className={styles.textEmail}>{item.email}</td>
      <td>
        <TagTooltip tags={item.tags} />
      </td>
      <td>{item.userType ? t("USER_TYPE." + item.userType.name) : t("USERS.LABEL.Null")}</td>
      <td className={styles.status}>
        <div className={styles.statusGroup}>
          <div className={`${styles.statusText} ${styles.activeLabel}`}>
            <span>{item.active ? t("USERS.LABEL.Activated") : t("USERS.LABEL.Deactivated")}</span>
          </div>
          {hasManagementPermissions && (
            <label className={styles.switch}>
              <input
                type="checkbox"
                checked={item.active}
                onChange={() => onStatusChange(item.id, !item.active, "status")}
              />
              <span className={`${styles.slider} ${styles.round}`}></span>
            </label>
          )}
        </div>
      </td>
      <td className={styles.status}>
        <div className={styles.statusGroup}>
          <div className={`${styles.statusText} ${styles.adminLabel}`}>
            <span>{item.userType && item.userType.name === "Admin" ? t("USERS.LABEL.Yes") : t("USERS.LABEL.No")}</span>
          </div>
          {hasManagementPermissions && (
            <label className={styles.switch}>
              <input
                type="checkbox"
                checked={item.userType && item.userType.name === "Admin"}
                onChange={() => onStatusChange(item.id, !(item.userType.name === "Admin"), "admin")}
              />
              <span className={`${styles.slider} ${styles.round}`}></span>
            </label>
          )}
        </div>
      </td>
      {hasManagementPermissions && (
        <td>
          <div className={styles.dropdownContainer} ref={dropdownRef}>
            <Icon
              icon="solar:menu-dots-bold"
              className={styles.dropIcon}
              width="20"
              height="20"
              onClick={handleDropdownToggle}
              ref={triggerRef}
            />
            {showDropdown && (
              <div
                className={styles.dropdownMenu}
                style={{ position: "fixed", top: coords.top, left: coords.left }}
              >
                <button onClick={() => { onEdit(item); setShowDropdown(false); }}>
                  <Icon icon="akar-icons:edit" className={styles.iconButton} width="20" height="20" />
                  {t("USERS.LABEL.Edit")}
                </button>
                <button onClick={() => { onDelete(item.id); setShowDropdown(false); }}>
                  <Icon icon="gravity-ui:trash-bin" className={styles.iconButton} width="20" height="20" />
                  {t("USERS.LABEL.Delete")}
                </button>
              </div>
            )}
          </div>
        </td>
      )}
    </tr>
  );
};

export default withTranslation()(UserRow);
