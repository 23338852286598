import React, { Component, createRef } from "react";
import { withTranslation } from "react-i18next";
import { Icon } from "@iconify/react";
import { connect } from "react-redux";
import { searchUsers, getUsersById, changeStatus, changeAdmin } from "../../services/UserService";
import { hasAdminRole } from "../../utils/credentials.util";
import CreateUsers from "./users-create/createUsers.component";
import UpdateUsers from "./users-update/updateUsers.component";
import DeleteUsers from "./users-delete/deleteUsers.component";
import ModalConfirmation from "../modal-confirmation/modalConfirmation.component";
import ChangeStatusUser from "./change-status-user/changeStatusUser.component";
import ChangeAdministratorUser from "./change-administrator-user/changeAdministratorUser.component";
import HomesModal from "./homes-modal/homesModal.component";
import UserRow from "./users-table/userRow.component";
import styles from "./styles/usersView.module.scss";

const mapStateToProps = (state) => ({
  userRoles: state.user.userRoles || [],
});

class UsersView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: "",
      usersData: [],
      loading: false,
      offset: 0,
      limit: 30,
      hasMore: true,
      showCreateUsers: false,
      showUpdateUsers: false,
      showDeleteUsers: false,
      showChangeStatus: false,
      showChangeAdministrator: false,
      showConfirmationModal: false,
      toChangeStatus: null,
      usersToUpdate: null,
      userToDelete: null,
      scrollToTop: false,
      selectedHomes: [],
      showHomesModal: false,
    };
    this.tableRef = createRef();
    this.debouncedFetchData = this.debounce(this.fetchData, 300);
  }

  debounce(func, wait) {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  handleSearchChange = (event) => {
    this.setState(
      {
        searchQuery: event.target.value,
        usersData: [],
        offset: 0,
        hasMore: true,
      },
      () => {
        this.debouncedFetchData();
      }
    );
  };

  fetchData = async () => {
    const { searchQuery, offset, limit, usersData, loading, hasMore } = this.state;
    if (loading || !hasMore) return;
    this.setState({ loading: true });
    try {
      const data = await searchUsers(searchQuery, undefined, offset, limit);
      if (data.length > 0) {
        const existingIds = new Set(usersData.map((user) => user.id));
        const newData = data.filter((user) => !existingIds.has(user.id));
        this.setState({
          usersData: [...usersData, ...newData],
          offset: offset + data.length,
          loading: false,
          hasMore: newData.length > 0,
        });
      } else {
        this.setState({ hasMore: false, loading: false });
      }
    } catch (error) {
      console.error("Error fetching users:", error);
      this.setState({ loading: false });
    }
  };

  componentDidMount() {
    this.fetchData();
    if (this.tableRef.current) {
      this.tableRef.current.addEventListener("scroll", this.handleScroll);
    }
  }

  componentWillUnmount() {
    if (this.tableRef.current) {
      this.tableRef.current.removeEventListener("scroll", this.handleScroll);
    }
  }

  handleScroll = () => {
    const { hasMore, loading } = this.state;
    if (!hasMore || loading) return;
    const scrollContainer = this.tableRef.current;
    if (scrollContainer) {
      const scrollPosition = scrollContainer.scrollTop + scrollContainer.clientHeight;
      const threshold = scrollContainer.scrollHeight - 100;
      if (scrollPosition >= threshold) {
        this.fetchData();
      }
    }
  };

  handleStatusChange = async ({ id, newStatus, value }) => {
    try {
      if (value === "status") {
        await changeStatus(id);
        this.setState((prevState) => ({
          usersData: prevState.usersData.map((user) =>
            user.id === id ? { ...user, active: newStatus } : user
          ),
        }));
      } else {
        const updatedUser = await changeAdmin(id);
        this.setState((prevState) => ({
          usersData: prevState.usersData.map((user) =>
            user.id === id ? updatedUser : user
          ),
        }));
      }
    } catch (error) {
      console.error("Error changing status:", error);
    }
  };

  handleShowCreateUsers = () => {
    this.setState({ showCreateUsers: true });
  };

  handleCloseCreateUsers = async (toFetch) => {
    if (toFetch) {
      this.setState(
        { searchQuery: "", usersData: [], offset: 0, hasMore: true },
        async () => {
          await this.fetchData();
          this.setState({ showConfirmationModal: true });
        }
      );
    }
    this.setState({ showCreateUsers: false });
  };

  handleCloseConfirmationModal = () => {
    this.setState({ showConfirmationModal: false });
  };

  handleShowChangeStatus = (id, newStatus, value) => {
    if (value === "status") {
      this.setState({ toChangeStatus: { id, newStatus, value }, showChangeStatus: true });
    } else {
      this.setState({ toChangeStatus: { id, newStatus, value }, showChangeAdministrator: true });
    }
  };

  handleCloseChangeStatus = async (toFetch) => {
    this.setState({
      showChangeStatus: false,
      showChangeAdministrator: false,
      toChangeStatus: null,
    });
  };

  handleShowUpdateUsers = async (user) => {
    try {
      const usersToUpdate = await getUsersById(user.id);
      this.setState({ usersToUpdate, showUpdateUsers: true });
    } catch (error) {
      console.error("Error fetching user for update:", error);
    }
  };

  handleCloseUpdateUsers = async (toFetch) => {
    if (toFetch) {
      this.setState(
        { searchQuery: "", usersData: [], offset: 0, hasMore: true },
        async () => {
          await this.fetchData();
        }
      );
    }
    this.setState({ showUpdateUsers: false, usersToUpdate: null });
  };

  handleShowDeleteUsers = (id) => {
    this.setState({ userToDelete: id, showDeleteUsers: true });
  };

  handleCloseDeleteUsers = async (toFetch) => {
    if (toFetch) {
      this.setState(
        { searchQuery: "", usersData: [], offset: 0, hasMore: true },
        async () => {
          await this.fetchData();
        }
      );
    }
    this.setState({ showDeleteUsers: false, userToDelete: null });
  };

  handleViewAllHomes = (homes) => {
    this.setState({ selectedHomes: homes, showHomesModal: true });
  };

  handleCloseHomesModal = () => {
    this.setState({ showHomesModal: false, selectedHomes: [] });
  };

  makeScroll = () => {
    if (this.tableRef.current) {
      this.tableRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };  

  hasManagementPermissions() {
    return hasAdminRole();
  }

  render() {
    const { t } = this.props;
    const {
      searchQuery,
      usersData,
      loading,
      showCreateUsers,
      showUpdateUsers,
      showDeleteUsers,
      showChangeStatus,
      showChangeAdministrator,
      toChangeStatus,
      usersToUpdate,
      userToDelete,
      scrollToTop,
      showConfirmationModal,
      selectedHomes,
      showHomesModal,
    } = this.state;

    return (
      <div className={styles.container}>
        <h1>{t("USERS.LABEL.Title")}</h1>
        <div className={styles.searchAndButton}>
          <div className={styles.searchContainer}>
            <Icon icon="lucide:search" width="18px" height="18px" className={styles.searchIcon} />
            <input
              type="text"
              placeholder={t("USERS.LABEL.SearchBar")}
              value={searchQuery}
              onChange={this.handleSearchChange}
              className={styles.searchBar}
            />
          </div>
          {this.hasManagementPermissions() && (
            <button className={styles.addUsersButton} onClick={this.handleShowCreateUsers}>
              {t("USERS.LABEL.AddUsers")}
            </button>
          )}
        </div>
        {/* Contenedor de la tabla con scroll infinito */}
        <div id="usersTable" className={styles.tableContainer} ref={this.tableRef}>
          <table className={styles.usersTable}>
            <thead>
              <tr>
                <th>{t("USERS.TABLE.NIU")}</th>
                <th>{t("USERS.TABLE.Name")}</th>
                <th>{t("USERS.TABLE.User")}</th>
                <th>{t("USERS.TABLE.Tag")}</th>
                <th>{t("USERS.TABLE.UserType")}</th>
                <th>{t("USERS.TABLE.Status")}</th>
                <th>{t("USERS.TABLE.Admin")}</th>
                {this.hasManagementPermissions() && <th></th>}
              </tr>
            </thead>
            <tbody>
              {usersData.map((item) => (
                <UserRow
                  key={item.id}
                  item={item}
                  onStatusChange={this.handleShowChangeStatus}
                  onEdit={this.handleShowUpdateUsers}
                  onDelete={this.handleShowDeleteUsers}
                  onViewAll={this.handleViewAllHomes}
                  t={t}
                  hasManagementPermissions={this.hasManagementPermissions()}
                />
              ))}
            </tbody>
          </table>
          {loading && (
            <div className={styles.spinnerContainer}>
              <div className={styles.spinner}></div>
            </div>
          )}
        </div>
        {showCreateUsers && <CreateUsers onClose={this.handleCloseCreateUsers} />}
        {showChangeStatus && toChangeStatus && (
          <ChangeStatusUser
            onStatusChange={this.handleStatusChange}
            onClose={this.handleCloseChangeStatus}
            id={toChangeStatus.id}
            newStatus={toChangeStatus.newStatus}
            value={toChangeStatus.value}
            activate={toChangeStatus.newStatus}
          />
        )}
        {showChangeAdministrator && toChangeStatus && (
          <ChangeAdministratorUser
            onStatusChange={this.handleStatusChange}
            onClose={this.handleCloseChangeStatus}
            id={toChangeStatus.id}
            newStatus={toChangeStatus.newStatus}
            value={toChangeStatus.value}
            activate={toChangeStatus.newStatus}
          />
        )}
        {showUpdateUsers && (
          <UpdateUsers user={usersToUpdate} onClose={(toFetch) => this.handleCloseUpdateUsers(toFetch)} />
        )}
        {showDeleteUsers && <DeleteUsers userId={userToDelete} onClose={this.handleCloseDeleteUsers} />}
        {showHomesModal && (
          <HomesModal homes={selectedHomes} onClose={this.handleCloseHomesModal} />
        )}
        {showConfirmationModal && (
          <ModalConfirmation
            title={t("USERS.CREATE.SuccessTitle")}
            customText={t("USERS.CREATE.SuccessMessage")}
            icon="fa-solid:user-plus"
            onClose={this.handleCloseConfirmationModal}
          />
        )}
        <div className={styles.scrollToTopButton} onClick={this.makeScroll}>
          <Icon icon="icon-park-outline:up" width="30px" height="30px" />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(withTranslation()(UsersView));
