import React, { useCallback, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { clearShowVideocall, clearVideocallInfo, clearHaveModerator } from '../../redux/slices/videocallSlice';
import { changeUserStateToEndCall } from '../../services/UserStateService.js';
import VideoCallJitsi from '../video-call-jitsi/videoCallJitsi.component';
import useBlockNavigation from '../../hooks/useBlockNavigation';
import ModalBlockNavigation from '../modal-block-navigation/modalBlockNavigation.component';
import { notifyVideoCallCancelation } from '../../services/VideoCallService';
import { changeLiveStatus } from '../../services/GroupService';
import { setSelectedGroup, updateGroup } from '../../redux/slices/groupSlice';
import { setSelectedComponent } from '../../redux/slices/layoutSlice';
import { hasFamiliarRole } from '../../utils/credentials.util';

const VideoCallWrapper = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const videocallInfo = useSelector((state) => state.videocall.videocallInfo);
    const haveModerator = useSelector((state) => state.videocall.haveModerator);
    const userId = useSelector((state) => state.user.id);
    const groups = useSelector((state) => state.groups.groups);
    const selectedGroup = useSelector((state) => state.groups.selectedGroup);
    const isFamiliarRole = hasFamiliarRole();

    const handleCallClosed = useCallback((iLeave = false, iAmModerator = false) => {
        const group = groups.find((group) => group.id === videocallInfo.groupCallId);
        if (videocallInfo.groupCallId !== null) {
            if (iLeave && !iAmModerator) {
                console.log('VIDEO CALL WRAPPER: I left the call', videocallInfo);
            } else if (videocallInfo.nParticipants <= 2 || videocallInfo.nParticipants === undefined || !haveModerator) {
                changeLiveStatus(videocallInfo.groupCallId, false);
                console.log('VIDEO CALL WRAPPER: Changing live status to false on group call', group);
                dispatch(updateGroup({ group: { ...group, live: false } }));
                if (selectedGroup && selectedGroup.id === videocallInfo.groupCallId) {
                    dispatch(setSelectedGroup({ ...selectedGroup, live: false }));
                }
            }
        } else {
            notifyVideoCallCancelation({ ...videocallInfo, token: undefined });
        }       

        dispatch(clearVideocallInfo());
        dispatch(clearShowVideocall());
        dispatch(clearHaveModerator());
        changeUserStateToEndCall(userId);        
        setTimeout(() => navigate(-1), 1000);
    }, [dispatch, userId, videocallInfo, groups, selectedGroup, haveModerator]);

    const callClosedExecuted = useRef(false);

    const callClosed = useCallback((iLeave = false, iAmModerator = false) => {
        if (callClosedExecuted.current) return;
        callClosedExecuted.current = true;
        handleCallClosed(iLeave, iAmModerator);

        setTimeout(() => { callClosedExecuted.current = false; }, 0);
    }, [handleCallClosed, navigate]);

    const { isModalVisible, handleCloseModal } = useBlockNavigation(!!videocallInfo, handleCallClosed);

    useEffect(() => {
        if (isFamiliarRole) {
            dispatch(setSelectedComponent('outlet'));
        }
    }, [isFamiliarRole, dispatch]);

    useEffect(() => {
        if (!videocallInfo) {
            dispatch(clearShowVideocall());
        }
    }, [videocallInfo, dispatch]);

    if (!videocallInfo) {
        return null;
    }

    return (
        <>
            <VideoCallJitsi
                appId={videocallInfo.appId}
                roomId={videocallInfo.roomId}
                token={videocallInfo.token}
                callClosed={callClosed}
            />
            {isModalVisible && (
                <ModalBlockNavigation
                    onClose={handleCloseModal}
                />
            )}
        </>
    );
};

export default VideoCallWrapper;
