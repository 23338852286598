import React, { Component, createRef } from 'react';
import { withTranslation } from 'react-i18next';
import { Icon } from '@iconify/react';
import { connect } from 'react-redux';
import { searchHomes, changeStatus } from '../../services/HomeService';
import { hasAdminRole } from '../../utils/credentials.util';
import CreateHome from './create-home/createHome.component';
import UpdateHome from './update-home/updateHome.component';
import DeleteHome from './delete-home/deleteHome.component';
import ModalConfirmation from '../modal-confirmation/modalConfirmation.component';
import HomeRow from './homes-table/homeRow.component';
import styles from './styles/homesView.module.scss';

const mapStateToProps = (state) => ({
  userRoles: state.user.userRoles || []
});

class HomesView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: "",
      homeData: [],
      loading: false,
      offset: 0,
      limit: 30,
      hasMore: true,
      showCreateHome: false,
      showUpdateHome: false,
      showDeleteHome: false,
      showCreateConfirmationModal: false,
      homeToUpdate: null,
      homeToDelete: null,
      scrollToTop: false,
    };
    this.tableRef = createRef();
    this.debouncedFetchData = this.debounce(this.fetchData, 300);
  }

  debounce(func, wait) {
    let timeout;
    return (...args) => {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(context, args), wait);
    };
  }

  handleSearchChange = (event) => {
    this.setState({
      searchQuery: event.target.value,
      homeData: [],
      offset: 0,
      hasMore: true,
    }, () => {
      this.debouncedFetchData();
    });
  };

  handleStatusChange = async (id, newStatus) => {
    try {
      await changeStatus(id);
      this.setState(prevState => ({
        homeData: prevState.homeData.map(home =>
          home.id === id ? { ...home, active: newStatus } : home
        )
      }));
    } catch (error) {
      console.error('Error changing status:', error);
    }
  };

  fetchData = async () => {
    const { searchQuery, offset, limit, homeData, loading } = this.state;
    console.log("fetchData called - offset:", offset, "limit:", limit);
    if (loading) return;
    this.setState({ loading: true });
    try {
      const data = await searchHomes(searchQuery, undefined, offset, limit);
      console.log("Data received:", data);
      if (data.length > 0) {
        const existingIds = new Set(homeData.map(item => item.id));
        const newData = data.filter(item => !existingIds.has(item.id));
        console.log("New data after filtering duplicates:", newData);
        this.setState({
          homeData: [...homeData, ...newData],
          offset: offset + data.length,
          loading: false,
          hasMore: newData.length > 0,
        });
      } else {
        this.setState({ hasMore: false, loading: false });
      }
    } catch (error) {
      console.error('Error fetching homes:', error);
      this.setState({ loading: false });
    }
  };

  componentDidMount() {
    this.fetchData();
    if (this.tableRef.current) {
      this.tableRef.current.addEventListener('scroll', this.handleScroll);
    }
  }

  componentWillUnmount() {
    if (this.tableRef.current) {
      this.tableRef.current.removeEventListener('scroll', this.handleScroll);
    }
  }

  handleScroll = () => {
    const { hasMore, loading } = this.state;
    if (!hasMore || loading) return;
    const scrollContainer = this.tableRef.current;
    if (scrollContainer) {
      const scrollPosition = scrollContainer.scrollTop + scrollContainer.clientHeight;
      const threshold = scrollContainer.scrollHeight - 100;
      if (scrollPosition >= threshold) {
        console.log("Reached threshold, loading more homes...");
        this.fetchData();
      }
    }
  };

  handleShowCreateHome = () => {
    this.setState({ showCreateHome: true });
  };

  handleCloseCreateHome = async (toFetch) => {
    if (toFetch) {
      this.setState({
        searchQuery: "",
        homeData: [],
        offset: 0,
        hasMore: true,
      }, this.fetchData);
      this.setState({ showCreateConfirmationModal: true });
    }
    this.setState({ showCreateHome: false });
  };

  handleCloseCreateConfirmationModal = () => {
    this.setState({ showCreateConfirmationModal: false });
  };

  handleShowUpdateHome = async (home) => {
    this.setState({ homeToUpdate: home, showUpdateHome: true });
  };

  handleCloseUpdateHome = async (toFetch) => {
    if (toFetch) {
      this.setState({
        searchQuery: "",
        homeData: [],
        offset: 0,
        hasMore: true,
      }, this.fetchData);
    }
    this.setState({ showUpdateHome: false, homeToUpdate: null });
  };

  handleShowDeleteHome = (id) => {
    this.setState({ homeToDelete: id, showDeleteHome: true });
  };

  handleCloseDeleteHome = async (toFetch) => {
    if (toFetch) {
      this.setState({
        searchQuery: "",
        homeData: [],
        offset: 0,
        hasMore: true,
      }, this.fetchData);
    }
    this.setState({ showDeleteHome: false, homeToDelete: null });
  };

  makeScroll = () => {
    if (this.tableRef.current) {
      this.tableRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };
  
  hasManagementPermissions() {
    return hasAdminRole();
  }

  render() {
    const { t } = this.props;
    const {
      searchQuery, homeData, loading, showCreateHome, showUpdateHome,
      homeToUpdate, showDeleteHome, homeToDelete, showCreateConfirmationModal, scrollToTop
    } = this.state;

    return (
      <div className={styles.container}>
        <h1>{t('HOMES.LABEL.Title')}</h1>
        <div className={styles.searchAndButton}>
          <div className={styles.searchContainer}>
            <Icon icon="lucide:search" width="18px" height="18px" className={styles.searchIcon} />
            <input
              type="text"
              placeholder={t('HOMES.LABEL.SearchBar')}
              value={searchQuery}
              onChange={this.handleSearchChange}
              className={styles.searchBar}
            />
          </div>
          {this.hasManagementPermissions() && (
            <button className={styles.addHomeButton} onClick={this.handleShowCreateHome}>
              {t('HOMES.LABEL.AddHome')}
            </button>
          )}
        </div>
        <div id="homesTable" className={styles.tableContainer} ref={this.tableRef}>
          <table className={styles.homeTable}>
            <thead>
              <tr>
                <th>{t('HOMES.TABLE.NIU')}</th>
                <th>{t('HOMES.TABLE.Address')}</th>
                <th>{t('HOMES.TABLE.Locality')}</th>
                <th>{t('HOMES.TABLE.Province')}</th>
                <th>{t('HOMES.TABLE.PostalCode')}</th>
                <th>{t('HOMES.TABLE.Status')}</th>
                {this.hasManagementPermissions() && <th></th>}
              </tr>
            </thead>
            <tbody>
              {homeData.map(item => (
                <HomeRow
                  key={item.id}
                  item={item}
                  onStatusChange={this.handleStatusChange}
                  onEdit={this.handleShowUpdateHome}
                  onDelete={this.handleShowDeleteHome}
                  hasManagementPermissions={this.hasManagementPermissions()}
                  t={t}
                />
              ))}
            </tbody>
          </table>
          {loading && (
            <div className={styles.spinnerContainer}>
              <div className={styles.spinner}></div>
            </div>
          )}
        </div>
        {showCreateHome && <CreateHome onClose={this.handleCloseCreateHome} />}
        {showUpdateHome && <UpdateHome home={homeToUpdate} onClose={this.handleCloseUpdateHome} />}
        {showDeleteHome && <DeleteHome homeId={homeToDelete} onClose={this.handleCloseDeleteHome} />}
        {showCreateConfirmationModal && (
          <ModalConfirmation
            title={t("HOMES.CREATE.SuccessTitle")}
            customText={t("HOMES.CREATE.SuccessMessage")}
            icon="mdi:home-plus"
            onClose={this.handleCloseCreateConfirmationModal}
          />
        )}
        <div className={styles.scrollToTopButton} onClick={this.makeScroll}>
          <Icon icon="icon-park-outline:up" width="30px" height="30px" />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(withTranslation()(HomesView));
