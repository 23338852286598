import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  notifyByEmail: null,
};

const userConfigSlice = createSlice({
  name: 'userConfig',
  initialState,
  reducers: {
    setUserConfig(state, action) {
      state.notifyByEmail = action.payload.notifyByEmail;
    },
    clearConfig(state) {
      state.notifyByEmail = null;
    },
  },
});

export const { setUserConfig, clearConfig } = userConfigSlice.actions;
export default userConfigSlice.reducer;
