import { useEffect, useState, useCallback } from 'react';
import { useBlocker } from 'react-router-dom';

const useBlockNavigation = (shouldBlock, onConfirm) => {
  const blocker = useBlocker(shouldBlock);
  const [isModalVisible, setModalVisible] = useState(false);

  const handleCloseModal = useCallback(
    (confirmed) => {
      setModalVisible(false);
      if (confirmed) {
        if (blocker.state === 'blocked') { 
          onConfirm();
          blocker.proceed();
        }
      } else {
        blocker.reset();
      }
    },
    [blocker, onConfirm]
  );
  

  useEffect(() => {
    if (blocker.state === 'blocked') {
      if (blocker.location.pathname === '/videocall') {
        blocker.proceed();
      } else {
        setModalVisible(true);
      }
    }
  }, [blocker]);

  return { isModalVisible, handleCloseModal };
};

export default useBlockNavigation;
