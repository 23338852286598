import React, { Component } from "react";
import ContentRow from "./content-table/contentRow.component"
import { withTranslation } from 'react-i18next';
import CreateContent from "./content-create/createContent.component";
import UpdateContent from "./content-update/updateContent.component";
import DeleteContent from "./content-delete/deleteContent.component";
import ModalConfirmation from '../modal-confirmation/modalConfirmation.component';
import styles from "./styles/contentView.module.scss";
import { Icon } from '@iconify/react';
import { searchContent } from '../../services/ContentService';
import { connect } from 'react-redux';
import { hasAdminRole, hasManagerRole, hasProfesionalRole } from '../../utils/credentials.util';

function debounce(func, wait) {
    let timeout;
    return function (...args) {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(context, args), wait);
    };
}

const mapStateToProps = (state) => ({
    userRoles: state.user.userRoles || []
});

class ContentView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchQuery: "",
            contentData: [],
            loading: false,
            showCreateContent: false,
            showUpdateContent: false,
            showDeleteContent: false,
            showCreateConfirmationModal: false,
            contentToUpdate: null,
            contentToDelete: null,
            scrollToTop: false,
            sortAscending: true,
            sortKey: 'name',
            offset: 0,
            limit: 30,
            hasMore: true,
        };
        this.tableRef = React.createRef();
        this.debouncedFetchData = debounce(this.fetchData, 300);
    }

    handleSearchChange = (event) => {
        this.setState({
            searchQuery: event.target.value,
            contentData: [],
            offset: 0,
            hasMore: true,
        }, () => {
            this.debouncedFetchData();
        });
    };

    handleStatusChange = async (id, newStatus) => {
        try {
            this.setState(prevState => ({
                contentData: prevState.contentData.map(content =>
                    content.id === id ? { ...content, active: newStatus } : content
                )
            }));
        } catch (error) {
            console.error('Error changing status:', error);
        }
    };

    fetchData = async () => {
        const { searchQuery, offset, limit, contentData, loading } = this.state;

        if (loading) return;

        this.setState({ loading: true });
        try {
            const data = await searchContent(searchQuery, limit, offset);
            console.log("CONTENT DATA", data);
            if (data.length > 0) {
                const existingIds = new Set(contentData.map(item => item.id));
                const newData = data.filter(item => !existingIds.has(item.id));

                this.setState({
                    contentData: [...contentData, ...newData],
                    offset: offset + data.length,
                    loading: false,
                }, () => {
                    if (newData.length === 0) {
                        this.setState({ hasMore: false });
                    }
                });
            } else {
                this.setState({
                    hasMore: false,
                    loading: false,
                });
            }
        } catch (error) {
            console.error('Error fetching content:', error);
            this.setState({ loading: false });
        }
    };

    componentDidMount() {
        this.fetchData();
        this.attachScrollListener();
    }

    componentWillUnmount() {
        this.detachScrollListener();
    }

    componentDidUpdate(prevProps, prevState) {
        const { scrollToTop } = this.state;

        if (scrollToTop && !prevState.scrollToTop) {
            if (this.tableRef.current) {
                this.tableRef.current.scroll({
                    top: 0,
                    behavior: "smooth"
                });
            }
            this.setState({ scrollToTop: false });
        }
    }

    attachScrollListener = () => {
        if (this.tableRef.current) {
            this.tableRef.current.addEventListener('scroll', this.handleScroll);
        }
    };

    detachScrollListener = () => {
        if (this.tableRef.current) {
            this.tableRef.current.removeEventListener('scroll', this.handleScroll);
        }
    };

    handleScroll = () => {
        const { hasMore, loading } = this.state;

        if (!hasMore || loading) return;

        const scrollContainer = this.tableRef.current;

        if (scrollContainer) {
            const scrollPosition = scrollContainer.scrollTop + scrollContainer.clientHeight;
            const threshold = scrollContainer.scrollHeight - 100;

            if (scrollPosition >= threshold) {
                this.fetchData();
            }
        }
    };

    handleShowCreateContent = () => {
        this.setState({ showCreateContent: true });
    };

    handleCloseCreateContent = async (toFetch) => {
        if (toFetch) {
            this.setState({
                searchQuery: '',
                contentData: [],
                offset: 0,
                hasMore: true,
            }, this.fetchData);
            this.setState({ showCreateConfirmationModal: true });
        }
        this.setState({ showCreateContent: false });
    };

    handleCloseCreateConfirmationModal = () => {
        this.setState({ showCreateConfirmationModal: false });
    };

    handleShowUpdateContent = async (content) => {
        try {
            this.setState({ contentToUpdate: content, showUpdateContent: true });
        } catch (error) {
            console.error('Error fetching content for update:', error);
        }
    };

    handleCloseUpdateContent = async (toFetch) => {
        if (toFetch) {
            this.setState({
                contentData: [],
                offset: 0,
                hasMore: true,
            }, this.fetchData);
        }
        this.setState({ showUpdateContent: false, contentToUpdate: null });
    };

    handleShowDeleteContent = (id) => {
        this.setState({ contentToDelete: id, showDeleteContent: true });
    };

    handleCloseDeleteContent = async (toFetch) => {
        if (toFetch) {
            this.setState({
                contentData: [],
                offset: 0,
                hasMore: true,
            }, this.fetchData);
        }
        this.setState({ showDeleteContent: false, contentToDelete: null });
    };

    makeScroll = () => {
        this.setState({ scrollToTop: true });
    };

    handleSort = () => {
        this.setState((prevState) => {
            const { sortAscending, sortKey, contentData } = prevState;
            const newSortAscending = !sortAscending;
            const sortedData = [...contentData].sort((a, b) => {
                const aValue = a[sortKey] ? a[sortKey].toString().toLowerCase() : '';
                const bValue = b[sortKey] ? b[sortKey].toString().toLowerCase() : '';
                if (aValue < bValue) return newSortAscending ? -1 : 1;
                if (aValue > bValue) return newSortAscending ? 1 : -1;
                return 0;
            });
            return {
                sortAscending: newSortAscending,
                contentData: sortedData,
            };
        });
    };

    hasCreateContentPermission() {
        return hasAdminRole() || hasManagerRole() || hasProfesionalRole()
    }

    render() {
        const { t } = this.props;
        const { searchQuery, contentData, loading, showCreateContent, showUpdateContent, contentToUpdate, showDeleteContent, contentToDelete, showCreateConfirmationModal, sortAscending } = this.state;

        return (
            <div className={styles.container}>
                <h1>{t('CONTENT.LABEL.Title')}</h1>
                <div className={styles.searchAndButton}>
                    <div className={styles.searchContainer}>
                        <Icon icon="lucide:search" width="18px" height="18px" className={styles.searchIcon} />
                        <input
                            type="text"
                            placeholder={t('CONTENT.LABEL.SearchBar')}
                            value={searchQuery}
                            onChange={this.handleSearchChange}
                            className={styles.searchBar}
                        />
                    </div>
                    {this.hasCreateContentPermission() && (
                        <button className={styles.addContentButton} onClick={this.handleShowCreateContent}>
                            {t('CONTENT.LABEL.AddContent')}
                        </button>
                    )}
                </div>
                <div id="contentTable" className={styles.tableContainer} ref={this.tableRef}>
                    <table className={styles.contentTable}>
                        <thead>
                            <tr>
                                <th onClick={this.handleSort} className={styles.sorting}>
                                    <Icon icon="mdi:compare-vertical" width="20" height="20" />
                                    {t('CONTENT.TABLE.Name')}
                                    {sortAscending ? <Icon icon="mdi:arrow-up" width="16" height="16" /> : <Icon icon="mdi:arrow-down" width="16" height="16" />}
                                </th>
                                <th>{t('CONTENT.TABLE.Resource')}</th>
                                <th>{t('CONTENT.TABLE.Category')}</th>
                                <th>{t('CONTENT.TABLE.Tags')}</th>
                                <th className={styles.centerColumn}>{t('CONTENT.TABLE.Status')}</th>
                                <th>{t('CONTENT.TABLE.User')}</th>
                                <th className={styles.centerColumn}>{t('CONTENT.TABLE.StartDate')}</th>
                                <th className={styles.centerColumn}>{t('CONTENT.TABLE.EndDate')}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {contentData.map((item) => (
                                <ContentRow
                                    key={item.id}
                                    item={item}
                                    onStatusChange={this.handleStatusChange}
                                    onEdit={this.handleShowUpdateContent}
                                    onDelete={this.handleShowDeleteContent}
                                    t={t}
                                />
                            ))}
                        </tbody>
                    </table>
                    {loading && (
                        <div className={styles.spinnerContainer}>
                            <div className={styles.spinner}></div>
                        </div>
                    )}
                </div>
                {showCreateContent && <CreateContent onClose={this.handleCloseCreateContent} />}
                {showUpdateContent && <UpdateContent content={contentToUpdate} onClose={(toFetch) => this.handleCloseUpdateContent(toFetch)} />}
                {showDeleteContent && <DeleteContent contentId={contentToDelete} onClose={this.handleCloseDeleteContent} />}

                {showCreateConfirmationModal && (
                    <ModalConfirmation
                        title={t("CONTENT.CREATE.SuccessTitle")}
                        customText={t("CONTENT.CREATE.SuccessMessage")}
                        icon="mdi:event-add"
                        onClose={this.handleCloseCreateConfirmationModal}
                    />
                )}
                <div className={styles.scrollToTopButton} onClick={this.makeScroll}>
                    <Icon icon="icon-park-outline:up" width="30px" height="30px" />
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(withTranslation()(ContentView));
